<template>
  <div class="announcements-add add-template">
    <g-background>
      <page-header>
        <h1>{{ translations.tcAddAnnouncement }}</h1>
      </page-header>
      <page-body>
        <section class="form-block">
          <b-form class="coordinator">
            <b-form-select v-model="current" :options="events" class="form-control g-select sm-select">
              <template slot="first">
                <option :value="null" disabled>{{ translations.tcSelectAType }}</option>
              </template>
            </b-form-select>
          </b-form>
        </section>
        <section class="form-buttons">
          <b-button :disabled="current === null" variant="primary"
            class="btn-w-sm flex-0 mr-0 mr-sm-3 w-100-sd mb-3 mb-sm-0" @click="handleNextClick">{{ translations.tcNext
            }}</b-button>
          <b-button variant="tertiary" class="btn-w-sm flex-0 mr-0 mr-sm-3 w-100-sd mb-3 mb-sm-0"
            @click="handleCancelClick">{{ translations.tcCancel }}</b-button>
        </section>
      </page-body>
    </g-background>
  </div>
</template>

<script>
/* eslint-disable */
import { mapActions, mapGetters } from 'vuex'
import gBackground from '@/components/gBackground.vue'
import pageBody from '@/components/page-components/PageBody.vue'
import pageFooter from '@/components/page-components/PageFooter.vue'
import pageHeader from '@/components/page-components/PageHeader.vue'
import { translationMixin } from '@/mixins/translationMixin'

export default {
  name: 'add-announcement',
  mixins: [translationMixin],
  data() {
    return {
      view_add_announcement_dropdown_controls: {
        state_announcement_dropdown_option: '6aa4f3f0-cdad-48c9-ab09-e241e1e37e6c',
        country_announcement_dropdown_option: 'f71291d3-04cb-424c-a206-718d63423c66',
      },
      current: null,
      events: [
        {
          value: 'camp',
          text: 'Camp',
        },
      ],
      events_st: [
        {
          value: 'state',
          text: 'State',
        },
      ],
      events_cn: [
        {
          value: 'state',
          text: 'Country',
        },
      ],
      events_committee: [
        {
          value: 'committee',
          text: 'Committee',
        },
      ],
      events_regional: [],
      translations: {},
    }
  },
  components: {
    gBackground: gBackground,
    pageBody: pageBody,
    pageFooter: pageFooter,
    pageHeader: pageHeader,
  },
  async created() {
    await Promise.all([this.getViewTranslations()]).then(() => {
      // Update the 'Select a type' dropdown with translated text
      this.$set(this.events[0], 'text', this.translations.tcCamp)
      this.$set(this.events_st[0], 'text', this.translations.tcState)
      this.$set(this.events_cn[0], 'text', this.translations.tcCountry)
      this.$set(this.events_committee[0], 'text', this.translations.tcCommittee)
    })
  },
  async mounted() {
    if (
      this.iCanSee(this.view_add_announcement_dropdown_controls.state_announcement_dropdown_option) &&
      this.userLogin.membertype.toLowerCase() === 'usa'
    ) {
      this.events = [...this.events, ...this.events_st]
    }
    if (
      this.iCanSee(this.view_add_announcement_dropdown_controls.country_announcement_dropdown_option) &&
      this.userLogin.membertype.toLowerCase() !== 'usa'
    ) {
      this.events = [...this.events, ...this.events_cn]
    }
    try {
      //  separate the committee announcements -- regional and non-regional
      await Promise.all([
        this.setLoadingStatus(true),
        await this.clearAll(),
        await this.getRegionalCommittees(),
        await this.getAddableCommittees(),
      ]).then(() => {
        if (!!this.userCommittees && this.userCommittees.areas.length > 0) {
          this.userCommittees.areas.forEach((uc) => {
            this.regionalCommittees.forEach((rc) => {
              if (rc.cmt_key === uc.value) {
                this.events_regional.push({
                  text: rc.value,
                  value: 'regional-committee ' + rc.cmt_org_key,
                })
              }
            })
          })
          // if all the userCommittees were included (except the 'All Committees' entry)
          this.events =
            !this.addableCommittees || !this.addableCommittees.areas
              ? [...this.events, ...this.events_regional]
              : [...this.events, ...this.events_committee, ...this.events_regional]
        }
      })
    } catch (e) {
      console.error(e)
    } finally {
      this.setLoadingStatus(false)
    }
  },
  methods: {
    ...mapActions({
      clearCommitteeList: 'announcements/clearCommitteeList',
      clearUserCommitteeSelections: 'announcements/clearUserCommitteeSelections',
      getAddableCommittees: 'announcements/getAddableCommittees',
      getRegionalCommittees: 'announcements/getRegionalCommittees',
      getStateRegionArea: 'announcements/getStateRegionArea',
      getCampStateLeadership: 'announcements/getCampStateLeadership',
      getCountryZones: 'announcements/getCountryZones',
      getCountryZoneCountries: 'announcements/getCountryZoneCountries',
      getCountryZoneMembers: 'announcements/getCountryZoneMembers',
      getStateLeadership: 'announcements/getStateLeadership',
      setAllUserCommittees: 'announcements/setAllUserCommittees',
      setLoadingStatus: 'menu/setLoadingStatus',
    }),
    async clearAll() {
      this.selectedCommittees = []
      await this.clearUserCommitteeSelections()
      await this.clearCommitteeList()
    },
    async handleCancelClick() {
      // await someClearingMethod()  -- figure a way to clear the store
      this.$router.push('/get-connected/stay-informed/announcements')
    },
    async handleNextClick() {
      const _current = {
        type: this.current.split(' ')[0],
        committee: this.current.split(' ')[1],
      }
      try {
        await Promise.all([
          this.setLoadingStatus(true),
          await (async () => {
            switch (_current.type) {
              case 'regional-committee':
                await this.getCountryZoneCountries(_current.committee)
                await this.getCampStateLeadership('Camp')
                break
              case 'committee':
                await this.setAllUserCommittees(this.events_regional)
                break
              case 'state':
                await this.getStateLeadership()
                await this.getCampStateLeadership('Camp')
                if (this.userCountryObject.type === 'domestic') {
                  await this.getStateRegionArea(null)
                } else {
                  await this.getCountryZones(this.userStateKey)
                }
                break
            }
          })(),
          this.$router.push({
            path: `/get-connected/stay-informed/announcements/add/add-${_current.type}-announcement`,
          }),
        ])
      } catch (e) {
        console.error(e)
      } finally {
        this.setLoadingStatus(false)
      }
    },
  },
  computed: {
    ...mapGetters({
      addableCommittees: 'announcements/addableCommittees',
      iCanSee: 'user/iCanSee',
      prefCulture: 'user/userPreferredCulture',
      regionalCommittees: 'announcements/regionalCommittees',
      userCommittees: 'user/userCommittees',
      userCountryObject: 'user/userCountryObject',
      userLogin: 'user/userLogin',
      userStateKey: 'user/userStateKey',
    }),
  },
}
</script>

<style lang="scss">
@import '@/styles/settings.scss';
@import '@/styles/bootstrap/b-button.scss';
@import '@/styles/bootstrap/b-button-group.scss';

.add-template {
  h1 {
    margin-bottom: 34px;
  }

  .g-select {
    margin-bottom: 44px;
    max-width: 400px !important;
  }

  @include breakpoint(sm) {
    .page-header {
      text-align: center;

      h1 {
        font-size: 42px;
      }
    }
  }

  .g-background {
    margin: 90px auto 104px;
  }
}
</style>
